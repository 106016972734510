// extracted by mini-css-extract-plugin
export var aboutMainCircleBlurBlue = "ScopingCallMain-module--aboutMainCircleBlurBlue--1dbe9";
export var application = "ScopingCallMain-module--application--9800b";
export var applicationHeading = "ScopingCallMain-module--applicationHeading--3ad5a";
export var applicationMap = "ScopingCallMain-module--applicationMap--82d8e";
export var approach = "ScopingCallMain-module--approach--5416a";
export var backgroundCircles = "ScopingCallMain-module--backgroundCircles--5d958";
export var banner = "ScopingCallMain-module--banner--1988f";
export var benefits = "ScopingCallMain-module--benefits--6087d";
export var bottomButton = "ScopingCallMain-module--bottomButton--6c127";
export var careerBtnApplyNow = "ScopingCallMain-module--careerBtnApplyNow--c5164";
export var careers = "ScopingCallMain-module--careers--7385f";
export var caseChallengeImage = "ScopingCallMain-module--caseChallengeImage--e361a";
export var caseTestimonialImage = "ScopingCallMain-module--caseTestimonialImage--e165c";
export var contact = "ScopingCallMain-module--contact--5cdcf";
export var contactButton = "ScopingCallMain-module--contactButton--dfb51";
export var contactOutlineCircle = "ScopingCallMain-module--contactOutlineCircle--f21d3";
export var contactOutlineCircleBenfits = "ScopingCallMain-module--contactOutlineCircleBenfits--c1ece";
export var coreValue = "ScopingCallMain-module--coreValue--54409";
export var coreValueColumbLeft = "ScopingCallMain-module--coreValueColumbLeft--7d0b1";
export var coreValueColumbRight = "ScopingCallMain-module--coreValueColumbRight--b56fd";
export var coreValueItem = "ScopingCallMain-module--coreValueItem--3272e";
export var coreValueItemBox = "ScopingCallMain-module--coreValueItemBox--56879";
export var coreValuesHeading = "ScopingCallMain-module--coreValuesHeading--a9138";
export var description = "ScopingCallMain-module--description--f4426";
export var flowContainer = "ScopingCallMain-module--flowContainer--ffa42";
export var flowItem = "ScopingCallMain-module--flowItem--f7a96";
export var flowItemDay = "ScopingCallMain-module--flowItemDay--02866";
export var flowItemDayMobile = "ScopingCallMain-module--flowItemDayMobile--99e55";
export var flowItemDayMobileContainer = "ScopingCallMain-module--flowItemDayMobileContainer--5b6c9";
export var flowItemMobile = "ScopingCallMain-module--flowItemMobile--63aea";
export var flowLeftContainer = "ScopingCallMain-module--flowLeftContainer--1b08d";
export var flowRightContainer = "ScopingCallMain-module--flowRightContainer--13c29";
export var flowRightContainerMobile = "ScopingCallMain-module--flowRightContainerMobile--e3476";
export var flowStop = "ScopingCallMain-module--flowStop--f3dc3";
export var followUs = "ScopingCallMain-module--followUs--ba38c";
export var gdnPromotePic = "ScopingCallMain-module--gdnPromotePic--9e265";
export var greenColor = "ScopingCallMain-module--greenColor--d0a6f";
export var heading = "ScopingCallMain-module--heading--729e1";
export var heroBackgroundCircleLeft = "ScopingCallMain-module--heroBackgroundCircleLeft--83a5b";
export var heroBackgroundInnerCircleGreen = "ScopingCallMain-module--heroBackgroundInnerCircleGreen--cf280";
export var heroBackgroundInnerCircleLeft = "ScopingCallMain-module--heroBackgroundInnerCircleLeft--ee30d";
export var heroBackgroundInnerCircleLeft2 = "ScopingCallMain-module--heroBackgroundInnerCircleLeft2--3d546";
export var heroBackgroundInnerCircleLeftBenfits = "ScopingCallMain-module--heroBackgroundInnerCircleLeftBenfits--998e7";
export var heroBackgroundInnerCircleOverflowText = "ScopingCallMain-module--heroBackgroundInnerCircleOverflowText--0c340";
export var heroBackgroundInnerCircleRight = "ScopingCallMain-module--heroBackgroundInnerCircleRight--ef5f2";
export var heroBannerMainContainer = "ScopingCallMain-module--heroBannerMainContainer--b295f";
export var heroWhiteBlurCirce = "ScopingCallMain-module--heroWhiteBlurCirce--65f9f";
export var igImageGroup1 = "ScopingCallMain-module--igImageGroup1--e304c";
export var igImageGroup1Item = "ScopingCallMain-module--igImageGroup1Item--3005d";
export var igImageGroup2 = "ScopingCallMain-module--igImageGroup2--b9b3e";
export var igImageGroup2Btm = "ScopingCallMain-module--igImageGroup2Btm--f54eb";
export var igImageGroup2BtmItem = "ScopingCallMain-module--igImageGroup2BtmItem--ee5f3";
export var igImageGroup2Item = "ScopingCallMain-module--igImageGroup2Item--8f8f6";
export var inNumberColumbLeft = "ScopingCallMain-module--inNumberColumbLeft--16fe3";
export var inNumberColumbRight = "ScopingCallMain-module--inNumberColumbRight--40edb";
export var inNumbersImage = "ScopingCallMain-module--inNumbersImage--5e93f";
export var inNumbersItem = "ScopingCallMain-module--inNumbersItem--2a1f9";
export var innumbers = "ScopingCallMain-module--innumbers--424bf";
export var insights = "ScopingCallMain-module--insights--a80fa";
export var instaContainer = "ScopingCallMain-module--instaContainer--dc5e6";
export var instaLeftBox = "ScopingCallMain-module--instaLeftBox--03da5";
export var instaRightBox = "ScopingCallMain-module--instaRightBox--aa968";
export var introDescriptionButtonSection = "ScopingCallMain-module--introDescriptionButtonSection--6f1ff";
export var introImageSection = "ScopingCallMain-module--introImageSection--59853";
export var introSectionCircleItem = "ScopingCallMain-module--introSectionCircleItem--eeeaa";
export var introSectionContainer = "ScopingCallMain-module--introSectionContainer--7f99e";
export var introSectionText = "ScopingCallMain-module--introSectionText--2c3d6";
export var keyTopicsImage = "ScopingCallMain-module--keyTopicsImage--d5398";
export var knowUs = "ScopingCallMain-module--knowUs--fa52b";
export var knowUsImage = "ScopingCallMain-module--knowUsImage--ce12a";
export var knowUsText = "ScopingCallMain-module--knowUsText--171d4";
export var pillars = "ScopingCallMain-module--pillars--e6511";
export var reportBtn = "ScopingCallMain-module--reportBtn--a19cd";
export var reportBtnGerman = "ScopingCallMain-module--reportBtnGerman--d0c1a";
export var singleBenefitTextBox = "ScopingCallMain-module--singleBenefitTextBox--2a493";
export var singleCaseChallenge = "ScopingCallMain-module--singleCaseChallenge--4a00d";
export var singleCaseChallengeFirst = "ScopingCallMain-module--singleCaseChallengeFirst--a8b4a";
export var singleCaseContentBlock = "ScopingCallMain-module--singleCaseContentBlock--f9fde";
export var singleCaseImageBox = "ScopingCallMain-module--singleCaseImageBox--9b702";
export var singleCaseImageBoxForth = "ScopingCallMain-module--singleCaseImageBoxForth--235d9";
export var singleCaseImageBoxSecond = "ScopingCallMain-module--singleCaseImageBoxSecond--da437";
export var singleCaseSolution = "ScopingCallMain-module--singleCaseSolution--19028";
export var singleCaseSolutionMobile = "ScopingCallMain-module--singleCaseSolutionMobile--39acd";
export var singleCaseTestimonial = "ScopingCallMain-module--singleCaseTestimonial--46c9c";
export var singleCaseTestimonialInner = "ScopingCallMain-module--singleCaseTestimonialInner--d2592";
export var singleCaseTextBox = "ScopingCallMain-module--singleCaseTextBox--298cb";
export var singleCaseTextBoxLast = "ScopingCallMain-module--singleCaseTextBoxLast--31ad6";
export var singleCaseTextFirst = "ScopingCallMain-module--singleCaseTextFirst--e7ef3";
export var testimonialBody = "ScopingCallMain-module--testimonialBody--80d22";
export var testimonialContent = "ScopingCallMain-module--testimonialContent--fc9ce";
export var testimonialName = "ScopingCallMain-module--testimonialName--802ec";
export var torchWrapper = "ScopingCallMain-module--torchWrapper--eafa8";
export var verticalLine = "ScopingCallMain-module--verticalLine--1b29e";
export var verticalLineMobile = "ScopingCallMain-module--verticalLineMobile--bc7d4";
export var yourBenefits = "ScopingCallMain-module--yourBenefits--4c6d4";
export var yourBenefitsIcon = "ScopingCallMain-module--yourBenefitsIcon--952d0";
export var yourBenefitsInner = "ScopingCallMain-module--yourBenefitsInner--a20f5";
export var yourBenefitsItem = "ScopingCallMain-module--yourBenefitsItem--ce708";